import React, {useState} from 'react';
import {ReactComponent as SquareIcon} from './square.svg'; // Подставьте путь к вашему SVG файлу
import {CSSTransition} from 'react-transition-group';
import b from './FAQ.module.scss';
import a from '../../Pages/MainPage.module.scss';

const FAQItem = ({title, text}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={b.Wrapper}>
            <div className={b.Header}>
                <div className={`${a.Desk4} ${b.color}`}>{title}</div>
                <button onClick={toggleOpen} className={isOpen ? b.RedButton : b.WhiteButton}/>
            </div>
            <hr/>
            <CSSTransition
                in={isOpen}
                classNames={{
                    enter: b.TextEnter,
                    enterActive: b.TextEnterActive,
                    exit: b.TextExit,
                    exitActive: b.TextExitActive,
                }}
                unmountOnExit
            >
                <div className={b.Text}>
                    <div className={`${a.Desk4} ${b.Text}`}>{text}</div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default FAQItem;

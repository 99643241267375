import MainPage from "./Pages/MainPage";

function App() {
  return (
      <div>
          <MainPage/>
      </div>
  );
}

export default App;

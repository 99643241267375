import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({ text, link, type }) => {
    let className = 'button';

    if (type === 'outline') {
        className += ' outline';
    } else if (type === 'ghost') {
        className += ' ghost';
    }

    return (
        <a href={link} className={className}>
            {text}
        </a>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'outline', 'ghost']),
};

Button.defaultProps = {
    type: 'primary',
};

export default Button;

import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import a from '../Pages/MainPage.module.scss';
import c from './Slider.module.scss';
import Dot from './Dot'; // Подставьте путь к вашему файлу стилей

const CardSlider = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        setCards([
            {
                title: 'Эти пазлы помогли мне сделать',
                points: [
                    'Более 1 000 000 подписчиков в моей сетке Телеграм-каналов',
                    'Доход 185 000 000 рублей с Телеграм',
                    'Постоянные рекламодатели',
                    'Создать лучший продукт по продвижению Телеграм «Телеграматика»',
                ],
            },
            {
                title: 'Эти пазлы помогли ученикам',
                points: [
                    'Привлечь 100 000+ подписчиков в свой Телеграм-канал',
                    'Выйти на доход от 30.000₽ до 200.000₽ стабильно',
                    'Создать свою онлайн школу',
                    'Повысить чеки на свои услуги',
                ],
            },
        ]); // Ваши карточки
    }, []);

    useEffect(() => {
        setProgress(slideIndex / (cards.length - 1) * 100);
    }, [slideIndex, cards]);

    const handleSwipe = (direction) => {
        if (direction === 'left') {
            setSlideIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
        } else if (direction === 'right') {
            setSlideIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    });

    return (
        <div className={c.Wrapper} {...handlers}>
            <div className={c.Slider} style={{ transform: `translateX(-${slideIndex * 100}%)` }}>
                {cards.map((card, index) => (
                    <div key={index} className={c.Card}>
                        <h6 className={a.Title}>{card.title}</h6>
                        {card.points.map((point, index) => (
                            <div key={index} className={a.Point}>
                                <Dot />
                                <p className={a.Desk1}>{point}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div className={c.Indicators}>
                {cards.map((_, index) => (
                    <div
                        key={index}
                        className={`${c.Indicator} ${slideIndex === index ? c.Active : ''}`}
                        onClick={() => setSlideIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CardSlider;
